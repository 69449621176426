import { Auth } from 'aws-amplify'
import { Permission } from '../../lib/s3Themes/types/user-type'

export const getIdToken = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()
  return currentUser.signInUserSession.idToken.jwtToken as string
}

export const getEmail = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()
  return currentUser.signInUserSession.idToken.payload.email as string
}

export const getPermissions = async (): Promise<Permission | undefined> => {
  const currentUser = await Auth.currentAuthenticatedUser()

  return currentUser.signInUserSession.idToken.payload.profile as Permission
}
