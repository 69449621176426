import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, useNavigate, useLocation } from 'react-router-dom'
import { AmplifyProvider, translations } from '@aws-amplify/ui-react'
import { Auth, Amplify, I18n, Hub } from 'aws-amplify'
import { Provider } from 'react-redux'

import { SnackbarProvider, SnackbarKey } from 'notistack'
import { Button } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ptLocale from 'date-fns/locale/pt-BR'

import App from './App'
import reportWebVitals from './reportWebVitals'

import theme from './config/theme'
import { amplifyConfig } from './config/amplify'

import LoginPage from './pages/login'
import ProcessingPage from './pages/processing'

import { AWS_COGNITO_AUTH_DOMAIN, AWS_COGNITO_SHARED_DOMAIN, HEAD_TITLE, SCOPE } from './config/env'
import { Header } from './components'
import { getEmail } from './services/aws'
import { store } from './redux/root'

const settings:{[keys:string]: unknown} = {
  aws_cognito_region: amplifyConfig.awsCognitoRegion,
  aws_user_pools_id: amplifyConfig.awsUserPoolsId,
  aws_user_pools_web_client_id: amplifyConfig.awsUserPoolsWebClientId,
  oauth: {
    domain: AWS_COGNITO_AUTH_DOMAIN,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    clientId: amplifyConfig.awsUserPoolsId,
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
}

if (window.location.hostname !== 'localhost') {
  // configura para que não tenha que se autenticar caso já esteja logado em um subdomínio
  settings.cookieStorage = {
    domain: AWS_COGNITO_SHARED_DOMAIN,
    path: '/',
    sameSite: 'strict',
    secure: true
  }
}

Amplify.configure(settings)
I18n.putVocabularies(translations)
I18n.setLanguage('pt-br')

const AuthenticationWrapper = () => {
  const [loading, setLoading] = React.useState(true)
  const [currentUser, setCurrentUser] = React.useState()
  const [data, setData] = React.useState<{ document?: string | null, version?: string | null }>({})
  const [currentEmail, setCurrentEmail] = React.useState<string | undefined>()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(currentUser => {
        setCurrentUser(currentUser)
        return getEmail()
      })
      .then(email => setCurrentEmail(email))
      .finally(() => {
        const documento = sessionStorage.getItem('documento')
        const version = sessionStorage.getItem('version')
        if (documento) {
          setData({ document: documento, version })
          navigate({ pathname: location.pathname, search: version ? `?documento=${documento}&v=${version}` : `?documento=${documento}` }, { replace: true })
        }
        setLoading(false)
      })

    const unsubscribe = Hub.listen('auth', data => {
      if (data.payload.event === 'signIn') {
        setCurrentUser(data.payload.data.username)
      } else if (data.payload.event === 'signOut') {
        setCurrentUser(undefined)
      }
    }
    )

    return unsubscribe
  }, [])

  if (loading) {
    return <ProcessingPage />
  }

  if (!currentUser || !currentEmail) {
    return <LoginPage />
  }

  return (
    <App currentEmail={currentEmail} document={data.document} version={data.version} />
  )
}

const AppWrapper = () => {
  const notistackRef = React.createRef<SnackbarProvider>()

  const onCloseNotification = (key: SnackbarKey) => () => {
    if (!notistackRef.current) {
      return
    }

    notistackRef.current.closeSnackbar(key)
  }

  return (
    <>
      <Header>
        <title>{HEAD_TITLE}: Relatório</title>
        <link rel='icon' href={SCOPE === 'sulbrasil' ? '/favicon-sb.ico' : '/logo.png'} />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#000000' />
        <meta name='description' content={`Acompanhe tudo pelo ${HEAD_TITLE} Dashboard`} />
        <link rel='apple-touch-icon' href={SCOPE === 'sulbrasil' ? '/favicon-sb.ico' : '/logo.png'} />
      </Header>
      <SnackbarProvider
        preventDuplicate
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        ref={notistackRef}
        action={(key: SnackbarKey) => (
          <Button onClick={onCloseNotification(key)} sx={{ color: '#fff' }}>Fechar</Button>
        )}
      >
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptLocale}>
            <CssBaseline />
            <BrowserRouter>
              <AuthenticationWrapper />
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <React.StrictMode>
    <AmplifyProvider>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </AmplifyProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
