import { combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import mainReducer from './stores/main'
import metadataReducer from './stores/metadata'

const rootReducer = combineReducers({
  main: mainReducer,
  metadata: metadataReducer
})

const composeEnhancer = composeWithDevTools
export const store = createStore(
  rootReducer,
  composeEnhancer()
)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
