import iconv from 'iconv-lite'

export const changeEncoding = (str: string, encoding: string): Buffer => {
  const buffer = iconv.encode(str, encoding)
  return buffer
}

export const removeSpecialCharacters = (str: string| null | undefined): string => {
  if (!str) {
    return ''
  }
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
