import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Box, Chip, TextField, TextFieldProps } from '@mui/material'

import { hashObject } from '../utils/crypto'

export type CommentFieldProps = Omit<TextFieldProps, 'multiline' | 'fullWidth' | 'minRow'> & {
  name: string
  control: Control
  defaultValue?: string | null
}

export default function CommentField ({ name, control, ...props }: CommentFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const parsedUrls = parseUrls(field.value ?? '')
        return <>
          <TextField
            multiline
            fullWidth
            minRows={3}
            inputProps={{
              style: {
                fontSize: '18px'
              }
            }}
            {...props}
            {...field}
          />
          {parsedUrls.length > 0 && <Box my={1}>
            {parsedUrls.map((url, index) => <Chip
              component='a'
              clickable
              key={hashObject({ url, index })}
              size='small'
              color='primary'
              label={url}
              sx={{ mx: 0.5, my: 0.5 }}
              target='_blank'
              href={url.startsWith('http') ? url : `http://${url}`}
            />)}
          </Box>}
        </>
      }}
    />
  )
}

const parseUrls = (text: string): Array<string> => {
  const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  const urlArray = text.match(expression)

  if (!urlArray) {
    return []
  }

  return urlArray
}
