import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { CognitoIdentityCredentials, fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import {
  S3Client as S3ClientAWS,
  S3ClientConfig,
  GetObjectCommand,
  PutObjectCommand,
  HeadObjectCommand,
  HeadObjectCommandOutput
} from '@aws-sdk/client-s3'
import { Buffer } from 'buffer'

import { AWS_COGNITO_REGION, AWS_IDENTITY_POOL_ID, AWS_IDENTITY_POOL_REGION, AWS_USER_POOLS_ID } from '../config/env'

export interface S3ClientProps {
  identityPoolId?: string
  region?: string
  s3Client?: S3ClientAWS
  accessToken: string
  credentials?: S3ClientConfig['credentials']
}

export type S3ClientType = {
  readFile: (input: { bucket: string, key: string, contentType?: string, versionId?: string, options?: { responseCacheControl?: string, responseExpires?: Date } }) => Promise<any>
  writeFile: (input: { bucket: string, key: string, data: any, contentType: string }) => Promise<any>
  headFile: (input: { bucket: string, key: string, versionId?: string }) => Promise<HeadObjectCommandOutput>
  _client: S3ClientAWS
}

const S3Client = ({
  identityPoolId,
  region,
  s3Client,
  credentials,
  accessToken
}: S3ClientProps): S3ClientType => {
  const internalRegion = region || AWS_IDENTITY_POOL_REGION
  const internalIdentityPoolId = identityPoolId || AWS_IDENTITY_POOL_ID

  const internalCredentials =
    credentials || fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: internalRegion }),
      identityPoolId: internalIdentityPoolId,
      logins: {
        [`cognito-idp.${AWS_COGNITO_REGION}.amazonaws.com/${AWS_USER_POOLS_ID}`]: accessToken
      }
    })

  const client = s3Client || new S3ClientAWS({
    region: 'us-east-1',
    credentials: internalCredentials
  })

  const readFile = async ({ bucket, key, versionId, contentType: contentTypeProps, options }: { bucket: string, key: string, contentType?: string, versionId?: string, options?: { responseCacheControl?: string, responseExpires?: Date } }) => {
    const command = new GetObjectCommand({
      Bucket: bucket,
      Key: key,
      ResponseContentType: contentTypeProps,
      ResponseExpires: options?.responseExpires,
      VersionId: versionId
    })

    const data = await client.send(command)

    if (!isReadableStream(data.Body)) {
      throw new Error(
        'Expected stream to be instance of ReadableStream, but got ' +
        typeof data.Body
      )
    }

    const contentType = data.ContentType || ''

    if (['application/octet-stream', 'binary/octet-stream'].includes(contentType)) {
      return streamToBuffer(data.Body)
    }

    const bodyContent = await streamToString(data.Body)

    try {
      if (data.ContentType === 'application/json') {
        return JSON.parse(bodyContent)
      }

      return bodyContent
    } catch (err) {
      return bodyContent
    }
  }

  const writeFile = async ({ bucket, key, data, contentType }: { bucket: string, key: string, data: any, contentType: string }) => {
    const body =
      contentType === 'application/json' ? JSON.stringify(data) : data

    const command = new PutObjectCommand({
      Bucket: bucket,
      Key: key,
      Body: body,
      ContentType: contentType
    })

    return await client.send(command)
  }

  const headFile = async ({ bucket, key, versionId }: { bucket: string, key: string, versionId?: string }): Promise<HeadObjectCommandOutput> => {
    const command = new HeadObjectCommand({
      Bucket: bucket,
      Key: key,
      VersionId: versionId
    })

    const output: HeadObjectCommandOutput = await client.send(command)

    return output
  }

  return {
    _client: client,
    readFile,
    headFile,
    writeFile
  }
}

async function streamToBuffer (stream: ReadableStream<any>): Promise<Buffer> {
  return new Promise((resolve, reject) => {
    const array: number[] = []

    const reader = stream.getReader()
    const processRead = ({ done, value }: ReadableStreamDefaultReadResult<number[]>) => {
      if (done) {
        resolve(Buffer.from(array))
        return
      }

      value.forEach(v => array.push(v))

      // Not done, keep reading
      reader.read().then(processRead).catch(reject)
    }

    // start read
    reader.read().then(processRead).catch(reject)
  })
}

const isReadableStream = (stream?: any): stream is ReadableStream<any> => {
  return stream instanceof ReadableStream
}

async function streamToString (stream: ReadableStream<any>): Promise<string> {
  return new Promise((resolve, reject) => {
    let text = ''
    const decoder = new TextDecoder('utf-8')

    const reader = stream.getReader()
    const processRead = ({ done, value }: ReadableStreamDefaultReadResult<BufferSource>) => {
      if (done) {
        resolve(text)
        return
      }

      text += decoder.decode(value)

      // Not done, keep reading
      reader.read().then(processRead).catch(reject)
    }

    // start read
    reader.read().then(processRead).catch(reject)
  })
}

export class StaticS3Client {
  private static instance?: S3ClientType
  private static accessToken?: string
  private static credentials?: CognitoIdentityCredentials

  private static async resolveCredentials () {
    if (!StaticS3Client.accessToken) {
      throw new Error('Access token is required')
    }

    const credentials = await fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: AWS_IDENTITY_POOL_REGION }),
      identityPoolId: AWS_IDENTITY_POOL_ID,
      logins: {
        [`cognito-idp.${AWS_COGNITO_REGION}.amazonaws.com/${AWS_USER_POOLS_ID}`]: StaticS3Client.accessToken
      }
    })()

    StaticS3Client.credentials = credentials
  }

  private static resolveInstance () {
    if (!StaticS3Client.accessToken) {
      throw new Error('Access token is required')
    }
    StaticS3Client.instance = S3Client({ accessToken: StaticS3Client.accessToken, credentials: StaticS3Client.credentials })
  }

  static async getInstance ({ accessToken }: { accessToken: string }): Promise<S3ClientType> {
    if (!StaticS3Client.accessToken || StaticS3Client.accessToken !== accessToken) {
      StaticS3Client.accessToken = accessToken
      StaticS3Client.credentials = undefined
      StaticS3Client.instance = undefined

      await StaticS3Client.resolveCredentials()
    }

    if (!StaticS3Client.instance) {
      StaticS3Client.resolveInstance()
    }

    return StaticS3Client.instance!
  }
}

export default S3Client
