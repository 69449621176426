import React from 'react'
import { Paper, Typography, Box } from '@mui/material'
import ProcessingIcon from '../../assets/icons/ProcessingIcon'

export default function Processing () {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'secondary.main',
      height: '100vh',
      overflow: 'hidden'
    }}>
      <Paper
        sx={{
          paddingY: 4,
          paddingX: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          overflowY: 'auto',
          maxHeight: '90vh',
          width: '75%',
          maxWidth: '600px'
        }}
      >
        <ProcessingIcon height={200} />
        <Typography component='h1' variant='h5'>
          Estamos processando o seu acesso
        </Typography>
        <Typography component='h2' variant='caption'>
          Aguarde um instante...
        </Typography>
      </Paper>
    </Box>
  )
}
