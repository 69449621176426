import axios from 'axios'

import { toCamelCase } from '../../utils/formatter'
import { factoryDate } from '../../utils/date'
import { ResponseWithErrorMessage } from '../../utils/hooks'

import { ARCHIVE_BASE_URL } from '../../config/env'
import { AggregatedFiles } from '../../lib/s3Themes/types'
import { TENANT } from '../../config/constant'

interface ItemVersion {
  date: Date
  url: string
}

export type ListVersionsResult = Array<ItemVersion>
export interface ListVersionsProps {
  document: string
}

interface ListVersionsResponse {
  data: Array<{ data: string, key: string }>
}

export const listVersions = async ({ document }: ListVersionsProps): Promise<ListVersionsResult> => {
  const url = `${ARCHIVE_BASE_URL}/list_aggregate`

  const params = {
    tenant: TENANT,
    document
  }

  const { data } = await axios.get(url, { params }).catch((err: ResponseWithErrorMessage) => {
    const message = err.response?.data?.message || err.message

    if (message === '\'NoneType\' object is not iterable') {
      return {
        data: {
          data: []
        }
      }
    }

    throw new Error(`Envia essa mensagem de erro para o suporte:\n${message}`)
  })

  const formattedData = toCamelCase(data) as ListVersionsResponse

  return formattedData.data.map(item => ({
    date: factoryDate(item.data),
    url: item.key
  }))
}

export interface SaveVersionProps {
  document: string
}

interface SaveVersionResponse {
  data: AggregatedFiles
}

export const saveVersion = async ({ document }: SaveVersionProps): Promise<AggregatedFiles> => {
  const url = `${ARCHIVE_BASE_URL}/aggregate_history`

  const params = {
    tenant: TENANT,
    document
  }

  const { data } = await axios.get(url, { params }).catch((err: ResponseWithErrorMessage) => {
    const message = err.response?.data?.message || err.message

    throw new Error(`Envia essa mensagem de erro para o suporte:\n${message}`)
  })

  const formattedData = toCamelCase(data) as SaveVersionResponse
  return formattedData.data
}
