import wrap from 'word-wrap'

import { hashObject } from '../utils/crypto'
import { removeSpecialCharacters } from '../utils/string'

export const visNetworkOptions = {
  autoResize: true,
  height: '100%',
  width: '100%',
  groups: {
    PESQUISADO: {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf005',
        size: 75,
        color: '#2b7ce9'
      }
    },
    ESTRANGEIRO: {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf007',
        size: 50,
        color: '#2b7ce9'
      }
    },
    'PESSOA FÍSICA': {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf007',
        size: 50,
        color: '#2b7ce9'
      }
    },
    'PESSOA FÍSICA RELACIONADA': {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf007',
        size: 50,
        color: '#2b7ce9'
      }
    },
    NULA: {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf1ad',
        size: 50,
        color: 'grey'
      }
    },
    ATIVA: {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf1ad',
        size: 50,
        color: 'violet'
      }
    },
    SUSPENSA: {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf1ad',
        size: 50,
        color: 'red'
      }
    },
    INAPTA: {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf1ad',
        size: 50,
        color: 'green'
      }
    },
    BAIXADA: {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf1ad',
        size: 50,
        color: 'black'
      }
    },
    FIP: {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf1ad',
        size: 50,
        color: 'brown'
      }
    },
    GESTOR: {
      shape: 'icon',
      icon: {
        face: '\'FontAwesome\'',
        code: '\uf1ad',
        size: 50,
        color: 'orange'
      }
    }
  },
  physics: {
    enabled: true,
    solver: 'repulsion',
    maxVelocity: 0,
    minVelocity: 0,
    repulsion: {
      centralGravity: 0.0,
      springLength: 300,
      springConstant: 0.01,
      nodeDistance: 400
    },
    stabilization: {
      enabled: true,
      iterations: 2000
    }
  },
  edges: {
    arrows: {
      to: false,
      from: false
    },
    width: 2,
    selectionWidth: 1
  },
  nodes: {
    font: {
      color: '#343434',
      size: 14,
      face: 'arial',
      background: 'none',
      strokeWidth: 0,
      strokeColor: '#ffffff',
      align: 'center'
    }
  }
}

const resolveEdgeColor = (relationship: string) => {
  const defaultColor = 'black'
  const colorMap = new Map([
    ['PRESIDENTE', 'blue'],
    ['SOCIO-ADMINISTRADOR', '#ff3232'],
    ['SOCIO', 'green'],
    ['TITULAR PESSOA FISICA RESIDENTE OU DOMICILIADO NO BRASIL', 'yellow'],
    ['TITULAR PESSOA JURÍDICA DOMICILIADA NO BRASIL', 'cyan'],
    ['ADMINISTRADOR', 'darkgreen']
  ])

  return {
    color: colorMap.get(relationship) || defaultColor,
    highlight: colorMap.get(relationship) || defaultColor
  }
}

export interface GraphNetworkProps {
  name?: string
}

export interface Node {
  id: string
  value: any
  attributes: {
    label: string
    group: string
    level: number
    metadata: {
      cpfCnpj: string
      missingDocument: any
    }
  }
}

export interface Edge {
  label: string
  color?: {
    color: string
  }
  metadata: Record<string, unknown>
}

export class GraphNetwork {
  graph: {
    name?: string
  }

  nodes: {
    [key: string]: Node
  }

  edges: {
    [sourceHash: string]: { [targetHash: string]: Edge }
  }

  constructor (attrs: GraphNetworkProps) {
    this.graph = { ...attrs }
    this.nodes = {}
    this.edges = {}
  }

  nextNodeId (): string {
    return Object.keys(this.nodes).length.toString()
  }

  nextEdgeId (): string {
    return Object.keys(this.edges).length.toString()
  }

  hashNode (node: [string, string]): string {
    return hashObject(
      Array.isArray(node) ? JSON.stringify(node) : node
    ).toString()
  }

  addNode (node: [string, string], attrs: Node['attributes'], skipRegisteredNodes = false) {
    const hash = this.hashNode(node)
    if (!Object.prototype.hasOwnProperty.call(this.nodes, hash)) {
      this.edges[hash] = {}
      this.nodes[hash] = {
        id: this.nextNodeId(),
        value: node,
        attributes: { ...attrs }
      }
    } else {
      if (skipRegisteredNodes === false) {
        throw new Error('Node already registered in graph.')
      }
    }
  }

  addEdge (sourceNode: [string, string], targetNode: [string, string], attrs: Edge) {
    const hashSource = this.hashNode(sourceNode)
    const hashTarget = this.hashNode(targetNode)

    if (!Object.prototype.hasOwnProperty.call(this.nodes, hashSource)) {
      throw new Error('Source node not registered in graph.')
    }
    if (!Object.prototype.hasOwnProperty.call(this.nodes, hashTarget)) {
      throw new Error('Target node not registered in graph.')
    }

    let dataDict = this.edges[hashSource][hashTarget] || {}
    dataDict = { ...dataDict, ...attrs, color: resolveEdgeColor(removeSpecialCharacters(attrs.label).toUpperCase()) }
    this.edges[hashSource][hashTarget] = dataDict
  }

  toVisNetwork () {
    const nodesView = []
    for (const nodeAttrs of Object.values(this.nodes)) {
      const wrapedLabel = wrap(nodeAttrs.attributes.label, { width: 30, trim: true, newline: '\n' })

      nodesView.push({
        id: nodeAttrs.id,
        label: wrapedLabel,
        group: nodeAttrs.attributes.group,
        metadata: nodeAttrs.attributes.metadata,
        margin: {
          top: 30
        }
      })
    }

    const edgesView = []
    for (const sourceNodeHash of Object.keys(this.edges)) {
      const neighborhoodNodes = this.edges[sourceNodeHash]
      const _from = this.nodes[sourceNodeHash].id
      for (const targetNodeHash of Object.keys(neighborhoodNodes)) {
        const _id: string = (edgesView.length + 1).toString()
        const _to = this.nodes[targetNodeHash].id

        edgesView.push({
          id: _id,
          from: _from,
          to: _to,
          label: neighborhoodNodes[targetNodeHash].label,
          metadata: neighborhoodNodes[targetNodeHash].metadata,
          color: neighborhoodNodes[targetNodeHash].color
        })
      }
    }

    return {
      nodes: nodesView,
      edges: edgesView,
      options: visNetworkOptions
    }
  }

  generateVisGraph (graphMetadata: Array<{
    document: string
    name: string
    group: string
    level: number
    isFip?: boolean
    relationship?: string
    documentConnectedPerson?: string
    nameConnectedPerson?: string
  }>, includeFips = false) {
    for (const item of graphMetadata) {
      if (item.isFip && includeFips === false) {
        continue
      }

      const missingDocument = item.document.length > 14
      this.addNode(
        [item.document, item.name],
        {
          label: item.name,
          group: item.group,
          level: item.level,
          metadata: {
            cpfCnpj: item.document,
            missingDocument
          }
        },
        true
      )

      if (item.documentConnectedPerson && item.nameConnectedPerson && item.relationship) {
        this.addEdge(
          [item.document, item.name],
          [item.documentConnectedPerson, item.nameConnectedPerson],
          { label: item.relationship, metadata: {} }
        )
      }
    }
    return this.toVisNetwork()
  }

  static generateLegend (graphMetadata: Array<{
    document: string
    name: string
    group: string
    level: number
    isFip?: boolean
    relationship?: string
    documentConnectedPerson?: string
    nameConnectedPerson?: string
  }>) {
    const uniqueGroups = Array.from(new Set(graphMetadata.map((item) => item.group)))
    return uniqueGroups.map((group) => {
      return {
        id: hashObject(group),
        label: group,
        class: removeSpecialCharacters(group.replace(/ /g, '_'))
      }
    })
  }
}
