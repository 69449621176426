import axios from 'axios'

import { COMMENTS_BASE_URL } from '../../config/env'
import { toCamelCase } from '../../utils/formatter'
import { ResponseWithErrorMessage } from '../../utils/hooks'
import { getEmail, getIdToken } from '../aws'

const nEntries = 1

interface PutComment {
  message: string
}
export const putComment = async ({ text, document, field }: { text: string, document: string, field: string }): Promise<PutComment> => {
  const token = await getIdToken()
  const email = await getEmail()

  const { data } = await axios.put(
    `${COMMENTS_BASE_URL}/putField`,
    {
      value: text,
      email
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-scope-id': 'boanota',
        'x-doc-type': 'relatorio',
        'x-username-id': email
      },
      params: {
        fields: field,
        key: document
      }
    }
  )

  const formattedData = toCamelCase(data) as PutComment
  return formattedData
}

interface GetComment {
  message: string
  blob: Array<{
    user: string,
    value: string,
    timestamp: Date
  }>
  locked: boolean
  lockMetadata: {
    user?: string,
    lastLockTimestamp?: Date
  }
}

export const getComment = async ({ document, field }: { document: string, field: string }): Promise<GetComment> => {
  const token = await getIdToken()
  const email = await getEmail()
  const { data } = await axios.get(`${COMMENTS_BASE_URL}/getField`, {
    params: {
      fields: field,
      key: document,
      n_entries: nEntries
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'x-scope-id': 'boanota',
      'x-doc-type': 'relatorio',
      'x-username-id': email
    }
  }).catch(err => {
    if (axios.isAxiosError(err) || err instanceof Error) {
      let errorMessage: string | undefined = err.message
      if (axios.isAxiosError(err)) {
        errorMessage = (err as ResponseWithErrorMessage).response.data.message
      }

      if (errorMessage === 'blob not found.') {
        return {
          data: {
            message: 'blob not found.',
            blob: [],
            locked: false,
            lockMetadata: {}
          }
        }
      }
    }

    throw err
  })

  interface GetCommentResponse {
    message: string
    blob: Array<{
      user: string,
      value: string,
      timestamp: string
    }>
    locked: boolean
    lockMetadata: {
      user?: string,
      timestamp?: string
    }
  }

  const formattedData = toCamelCase(data) as GetCommentResponse
  return {
    ...formattedData,
    blob: formattedData.blob.map((item) => ({ ...item, timestamp: new Date(parseFloat(item.timestamp) * 1000) })),
    lockMetadata: {
      user: formattedData.lockMetadata.user,
      lastLockTimestamp: formattedData.lockMetadata.timestamp !== undefined ? new Date(parseFloat(formattedData.lockMetadata.timestamp) * 1000) : undefined
    }
  }
}

interface LockComment {
  message: string
}
export const lockComment = async ({ document, field }: { document: string, field: string }): Promise<LockComment> => {
  const token = await getIdToken()
  const email = await getEmail()

  const { data } = await axios.get(`${COMMENTS_BASE_URL}/lockField`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-scope-id': 'boanota',
      'x-doc-type': 'relatorio',
      'x-username-id': email
    },
    params: {
      fields: field,
      key: document
    }
  })

  const formattedData = toCamelCase(data) as LockComment
  return formattedData
}

interface UnlockComment {
  message: string
}
export const unlockComment = async ({ document, field }: { document: string, field: string }): Promise<UnlockComment> => {
  const token = await getIdToken()
  const email = await getEmail()

  const { data } = await axios.get(`${COMMENTS_BASE_URL}/unlockField`, {
    params: {
      fields: field,
      key: document
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'x-scope-id': 'boanota',
      'x-doc-type': 'relatorio',
      'x-username-id': email
    }
  })

  const formattedData = toCamelCase(data) as UnlockComment
  return formattedData
}

interface GetAllComments {
  message: string
  blobs: {
    [key: string]: any
  }
}

export const getAllComments = async ({ document }: { document: string }) => {
  const nEntries = 1

  const token = await getIdToken()
  const email = await getEmail()

  const { data } = await axios.get(`${COMMENTS_BASE_URL}/getKey`, {
    params: {
      key: document,
      n_entries: nEntries
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'x-scope-id': 'boanota',
      'x-doc-type': 'relatorio',
      'x-username-id': email
    }
  })

  const formattedData = toCamelCase(data) as GetAllComments
  return formattedData
}

export const removeFields = async ({ document, fields }: { document: string, fields: string[] }) => {
  const token = await getIdToken()
  const email = await getEmail()

  await axios.post(`${COMMENTS_BASE_URL}/deleteField`, {
    key: document,
    field_list: fields
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-scope-id': 'boanota',
      'x-doc-type': 'relatorio',
      'x-username-id': email
    }
  }
  )
}
