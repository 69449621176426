import { Auth } from 'aws-amplify'

import { AWS_USER_POOLS_ID, SCOPE } from '../config/env'

export const isIndremaUser = async (): Promise<boolean> => {
  const groups = await Auth.currentAuthenticatedUser().then(
    userData => userData.signInUserSession.idToken.payload['cognito:groups']
  )

  const indremaGroup = `${AWS_USER_POOLS_ID}_indrema`
  return groups.includes(indremaGroup)
}

export const resolveInternalScope = async (): Promise<string> => {
  const isIndrema = await isIndremaUser()

  if (isIndrema) {
    return 'indrema'
  }

  return resolveScope()
}

export const resolveScope = (): string => {
  return SCOPE
}
