import axios from 'axios'

import { RATING_BASE_URL } from '../../config/env'

import { getIdToken } from '../aws'

import { RatingDataInterface } from '../../lib/s3Themes/types/rating'

import { toCamelCase } from '../../utils/formatter'

export interface UpdateRatingProps {
  document: string
  executionDate: string
}

export const updateRating = async ({ document, executionDate }: UpdateRatingProps): Promise<RatingDataInterface> => {
  const url = `${RATING_BASE_URL}/boanota/rating`

  const token = await getIdToken()

  const payload = {
    document,
    execution_date: executionDate
  }

  const { data } = await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  const formattedData = toCamelCase(data) as Response

  return formattedData.data
}

interface Response {
  data: RatingDataInterface
  message: string
}
