export type PROGRESS_STATUS = 'loaded' | 'pending' | 'error' | 'partialError'

export const PROGRESS_TYPES: {
  LOADED: PROGRESS_STATUS,
  PENDING: PROGRESS_STATUS,
  ERROR: PROGRESS_STATUS,
  PARTIAL_ERROR: PROGRESS_STATUS,
} = {
  LOADED: 'loaded',
  PENDING: 'pending',
  ERROR: 'error',
  PARTIAL_ERROR: 'partialError'
}
