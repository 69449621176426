import camelcaseKeys from 'camelcase-keys'

export const formatCurrency = (
  number: number | undefined | null,
  lang?: string | null,
  currency?: string | null,
  options: Intl.NumberFormatOptions & {
    fractionDigits?: number
  } = {}
) => {
  if (number === undefined || number === null) {
    return ''
  }

  const { fractionDigits, ...rest } = options
  const internalLang = lang || 'pt-BR'
  const internalCurrency = currency || 'BRL'

  const formatter = new Intl.NumberFormat(internalLang, {
    style: 'currency',
    currency: internalCurrency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    ...rest
  })

  return formatter.format(number)
}

export const formatCnpj = (cnpj?: string | null) => {
  if (!cnpj) {
    return ''
  }

  cnpj = cnpj.replace(/[^\d]/g, '')

  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export const formatCpf = (cpf?: string | null) => {
  if (!cpf) {
    return ''
  }
  cpf = cpf.replace(/[^\d]/g, '')

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatDocument = (document?: string | null) => {
  if (!document) {
    return ''
  }

  const cleanedDocument = document.replace(/\D/g, '')
  const isCPF = cleanedDocument.length === 11

  return isCPF
    ? formatCpf(cleanedDocument)
    : formatCnpj(cleanedDocument)
}

export const formatPercent = (
  number: number,
  lang?: string | null,
  options: Intl.NumberFormatOptions & {
    fractionDigits?: number
  } = {}
) => {
  const internalLang = lang || 'pt-BR'
  const formatter = new Intl.NumberFormat(internalLang, {
    style: 'percent',
    minimumFractionDigits: options.fractionDigits,
    maximumFractionDigits: options.fractionDigits,
    ...options
  })

  return formatter.format(number)
}

export const formatDecimal = (
  number: number,
  lang = 'pt-BR',
  options: Intl.NumberFormatOptions = {}
) => {
  const formatter = new Intl.NumberFormat(lang, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options
  })

  return formatter.format(number)
}

export const firstLetterUpperCase = (word?: string | null) => {
  if (!word) {
    return ''
  }

  const firstLetter = word.charAt(0).toUpperCase()
  const otherLetters = word.slice(1).toLowerCase()

  return firstLetter + otherLetters
}

export const capitalize = (value?: string | null) => {
  if (!value) {
    return ''
  }

  return value
    .split(' ')
    .map(word => firstLetterUpperCase(word))
    .join(' ')
}

export const toCamelCase = <T>(value: string | object | Array<unknown>): string | object | Array<unknown> => {
  if (typeof value === 'string') {
    return Object.keys(camelcaseKeys({ [value]: '' }))[0] as string
  } else if (Array.isArray(value)) {
    return camelcaseKeys(value, { deep: true }) as Array<T>
  } else {
    return camelcaseKeys(value, { deep: true }) as object
  }
}

export const formatBoolean = (value?: boolean | null) => {
  if (value === undefined || value === null) {
    return '-'
  }

  return value ? 'sim' : 'não'
}

export const capitalizeWithAcronym = (value?: string | null) => {
  if (value === undefined || value === null) {
    return ''
  }

  const acronyms = [
    'LTDA',
    'MEI',
    'SLU',
    'SA',
    'SS',
    'EIRELI'
  ]

  const preposition = [
    'de',
    'da',
    'do',
    'das',
    'dos',
    'e',
    'a',
    'o'
  ]
  return value
    .split(' ')
    .map(word => preposition.includes(word.toLowerCase()) ? word.toLowerCase() : acronyms.includes(word.toUpperCase().replaceAll(/\.|\//g, '')) ? word.toUpperCase() : firstLetterUpperCase(word))

    .join(' ')
}

export const parseCnpj = (cnpj?: string | null) => {
  if (!cnpj) {
    return {
      formatted: null,
      partes: {
        raiz: '',
        filial: '',
        digito: ''
      },
      raw: null
    }
  }

  const cleanedCnpj = cnpj.replace(/\D/g, '')
  const parsedCnpj = parseInt(cleanedCnpj).toString().padStart(14, '0')
  const raiz = parsedCnpj.slice(0, 8)
  const filial = parsedCnpj.slice(8, 12)
  const digito = parsedCnpj.slice(12, 14)

  const formattedCnpj = `${raiz}.${filial}/${digito}`
  return {
    formatted: formattedCnpj,
    partes: {
      raiz,
      filial,
      digito
    },
    raw: parsedCnpj
  }
}

export const parseCpf = (cpf?: string | null) => {
  if (!cpf) {
    return {
      formatted: null,
      partes: {
        raiz: '',
        digito: ''
      },
      raw: null
    }
  }

  const cleanedCpf = cpf.replace(/\D/g, '')
  const parsedCpf = parseInt(cleanedCpf).toString().padStart(11, '0')
  const raiz = parsedCpf.slice(0, 9)
  const digito = parsedCpf.slice(9, 11)

  const formattedCpf = parsedCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

  return {
    formatted: formattedCpf,
    partes: {
      raiz,
      digito
    },
    raw: parsedCpf
  }
}
