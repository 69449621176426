import { computeForecast } from './forecast'
import { listVersions, saveVersion } from './archive'
import { getAllComments, getComment, lockComment, putComment, removeFields, unlockComment } from './comments'
import { existsSpread, updateSpread } from './spread'
import { updateRating } from './rating'

const API = {
  forecast: {
    compute: computeForecast
  },
  archive: {
    list: listVersions,
    save: saveVersion
  },
  comments: {
    put: putComment,
    lock: lockComment,
    get: getComment,
    unlock: unlockComment,
    remove: removeFields,
    all: getAllComments
  },
  spread: {
    update: updateSpread,
    exists: existsSpread
  },
  rating: {
    update: updateRating
  }
}

export default API
