import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material'

import { MdEdit, MdSave } from 'react-icons/md'

import { addFieldInEditMode, removeFieldInEditMode } from '../../redux/stores/metadata'

import { useCommentsField } from '../../lib/comments/hooks'
import { isCommentDisabled } from '../../lib/comments/disable'

import { useAppDispatch, useAppSelector } from '../../utils/hooks'

const IntegratedTextField = React.forwardRef(({ name, label, control, ...props }: Omit<TextFieldProps, 'name' | 'ref'> & { name: string, control: Control }, ref) => {
  const { editable, lockField, saveField } = useCommentsField(name)
  const [mode, permission, userType] = useAppSelector(state => [state.metadata.mode, state.metadata.permission, state.metadata.userType])
  const dispatch = useAppDispatch()

  return (
    <Box sx={{ textAlign: 'left', display: 'flex', flexDirection: 'row' }} ref={ref}>
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          <TextField
            label={label}
            variant='outlined'
            size='small'
            fullWidth
            aria-readonly={!editable}
            inputProps={{
              readOnly: !editable,
              style: {
                fontSize: '16px'
              }
            }}
            {...props}
            {...field}
          />}
      />
      {mode === 'committee' && <IconButton
        color='primary'
        disabled={isCommentDisabled({ name, permission, userType })}
        onClick={async () => {
          if (editable) {
            await saveField()
            dispatch(removeFieldInEditMode(name))
          } else {
            await lockField()
            dispatch(addFieldInEditMode(name))
          }
        }}
      >
        {editable ? <MdSave size={16} /> : <MdEdit size={16} />}
      </IconButton>}
    </Box>
  )
})

IntegratedTextField.displayName = 'IntegratedTextField'

export default IntegratedTextField
