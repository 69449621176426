import { Permission, UserTypeDataInterface } from '../../lib/s3Themes/types/user-type'

// Action Types
export const Types = {
  SET_MODE: 'METADATA/SET_MODE',
  SET_PERMISSION: 'METADATA/SET_PERMISSION',
  SET_USER_TYPE: 'METADATA/SET_USER_TYPE',
  ADD_FIELD_IN_EDIT_MODE: 'METADATA/ADD_FIELD_IN_EDIT_MODE',
  REMOVE_FIELD_IN_EDIT_MODE: 'METADATA/REMOVE_FIELD_IN_EDIT_MODE',
  SET_POLLING: 'METADATA/SET_POLLING'
}

type Keys = keyof typeof Types
type Values = typeof Types[Keys]

// Reducer
interface IInitialState {
  mode: 'viewOnly' | 'committee'
  userType?: UserTypeDataInterface
  permission?: Permission
  fieldsInEditMode: string[]
  pollingEnabled: boolean
}
const initialState: IInitialState = {
  mode: 'viewOnly',
  fieldsInEditMode: [],
  permission: undefined,
  userType: undefined,
  pollingEnabled: false
}

interface IAction {
  type: Values,
  payload: Partial<IInitialState>
}

export default function reducer (state = initialState, action: IAction): IInitialState {
  switch (action.type) {
    case Types.SET_MODE: {
      return {
        ...state,
        mode: action.payload.mode ?? 'viewOnly'
      }
    }
    case Types.SET_PERMISSION: {
      return {
        ...state,
        permission: action.payload.permission
      }
    }
    case Types.SET_USER_TYPE: {
      return {
        ...state,
        userType: action.payload.userType
      }
    }
    case Types.ADD_FIELD_IN_EDIT_MODE: {
      const fieldsInEditMode = Array.from(new Set(state.fieldsInEditMode.concat(...(action.payload.fieldsInEditMode || []))))

      return {
        ...state,
        fieldsInEditMode
      }
    }
    case Types.REMOVE_FIELD_IN_EDIT_MODE: {
      const fieldsToRemove = action.payload.fieldsInEditMode || []
      const fieldsInEditMode = state.fieldsInEditMode.filter(field => fieldsToRemove.includes(field) === false)

      return {
        ...state,
        fieldsInEditMode
      }
    }
    case Types.SET_POLLING: {
      return {
        ...state,
        pollingEnabled: action.payload.pollingEnabled ?? false
      }
    }
    default:
      return state
  }
}

// Action Creators
export function setMode (mode: IInitialState['mode']): IAction {
  return {
    type: Types.SET_MODE,
    payload: { mode }
  }
}

export function setPermission (permission: IInitialState['permission']): IAction {
  return {
    type: Types.SET_PERMISSION,
    payload: { permission }
  }
}

export function setUserType (userType: IInitialState['userType']): IAction {
  return {
    type: Types.SET_USER_TYPE,
    payload: { userType }
  }
}

export function addFieldInEditMode (field: string): IAction {
  return {
    type: Types.ADD_FIELD_IN_EDIT_MODE,
    payload: { fieldsInEditMode: [field] }
  }
}

export function removeFieldInEditMode (field: string): IAction {
  return {
    type: Types.REMOVE_FIELD_IN_EDIT_MODE,
    payload: { fieldsInEditMode: [field] }
  }
}

export function setPolling (pollingEnabled: boolean): IAction {
  return {
    type: Types.SET_POLLING,
    payload: { pollingEnabled }
  }
}
