import React, { useState } from 'react'
import { Box, Button, Container, TextField, Typography } from '@mui/material'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'

function NotFound (): JSX.Element {
  const [document, setDocument] = useState<string>('')

  return (
    <Box sx={{ pt: 2, pb: 2, flex: 1, overflow: 'auto', justifyContent: 'center', alignItems: 'center' }}>
      <Container maxWidth='xl' sx={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Typography sx={{ textAlign: 'center' }}>
          <WarningAmberIcon color='warning' sx={{ fontSize: 88 }} />
        </Typography>
        <Typography variant='body1' sx={{ textAlign: 'center', mb: 2 }}>
          Você precisa informar o número do documento
        </Typography>
        <TextField
          variant='outlined'
          size='small'
          value={document}
          onChange={(e) => setDocument(e.target.value.replace(/\D/g, ''))}
        />
        <Button sx={{ mt: 2 }} variant='contained' onClick={() => {
          window.location.href = `?documento=${document}`
        }}>Acessar</Button>
      </Container>
    </Box>
  )
}

export default NotFound
