import { toCamelCase } from '../../utils/formatter'
import { Permission, UserTypeDataInterface } from '../s3Themes/types/user-type'

export const isCommentDisabled = ({ name: fieldName, permission, userType }: { name: string, permission: Permission | undefined, userType?: UserTypeDataInterface }) => {
  if (!permission || !userType) {
    return true
  }

  if (permission === 'all') {
    return false
  }

  const fixedPermission = toCamelCase(permission) as keyof UserTypeDataInterface
  const names = userType[fixedPermission]

  return !names.some(name => fieldName.startsWith(name))
}
