import React, { forwardRef } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Box, Chip, TextField, TextFieldProps, Button } from '@mui/material'

import { MdEdit, MdSave } from 'react-icons/md'

import { addFieldInEditMode, removeFieldInEditMode } from '../../redux/stores/metadata'

import { useCommentsField } from '../../lib/comments/hooks'
import { isCommentDisabled } from '../../lib/comments/disable'

import { hashObject } from '../../utils/crypto'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'

export type IntegratedCommentFieldProps = Omit<TextFieldProps, 'multiline' | 'fullWidth' | 'minRow'> & {
  name: string
  control: Control
  defaultValue?: string | null
}

const IntegratedCommentField = forwardRef<typeof TextField, IntegratedCommentFieldProps>(({ name, control, ...props }, ref) => {
  const { editable, lockField, saveField } = useCommentsField(name)
  const [mode, permission, userType] = useAppSelector(state => [state.metadata.mode, state.metadata.permission, state.metadata.userType])
  const dispatch = useAppDispatch()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: fieldData }) => {
        const { ref: fieldRef, ...field } = fieldData
        const parsedUrls = parseUrls(field.value ?? '')
        return <>
          <TextField
            multiline
            fullWidth
            minRows={3}
            aria-readonly={!editable}
            inputProps={{
              readOnly: !editable,
              style: {
                fontSize: '18px'
              }
            }}
            inputRef={ref}
            {...props}
            {...field}
          />
          {mode === 'committee' && <Box sx={{ mt: 2 }}><Button
            color='primary'
            variant='outlined'
            endIcon={editable ? <MdSave /> : <MdEdit />}
            disabled={isCommentDisabled({ name, permission, userType })}
            onClick={async () => {
              if (editable) {
                await saveField()
                dispatch(removeFieldInEditMode(name))
              } else {
                await lockField()
                dispatch(addFieldInEditMode(name))
              }
            }}
          >
            {editable ? 'Salvar' : 'Editar'}
          </Button></Box>}
          {parsedUrls.length > 0 && <Box my={1}>
            {parsedUrls.map((url, index) => <Chip
              component='a'
              clickable
              key={hashObject({ url, index })}
              size='small'
              color='primary'
              label={url}
              sx={{ mx: 0.5, my: 0.5 }}
              target='_blank'
              href={url.startsWith('http') ? url : `http://${url}`}
            />)}
          </Box>}
        </>
      }}
    />
  )
})

IntegratedCommentField.displayName = 'IntegratedCommentField'

export default IntegratedCommentField

const parseUrls = (text: string): Array<string> => {
  const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  const urlArray = text.match(expression)

  if (!urlArray) {
    return []
  }

  return urlArray
}
