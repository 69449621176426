import { addSeconds } from 'date-fns'

import { resolveScope } from '../../utils/auth'
import { factoryDate } from '../../utils/date'
import { toCamelCase } from '../../utils/formatter'

import { AWS_BUCKET_NAME } from '../../config/env'

import { S3ClientType } from '../s3'
import { parseAllComments, UserCommentsResponseInterface } from '../comments/parser'

import API from '../../services/api'

import { AggregatedFiles, SerasaDataInterface, VisitasDataInterface, PgfnDataInterface, BasicInfoDataInterface, ProtestoDataInterface, LogsulDataInterface, PepDataInterface, BndesDiretoEIndiretoDataInterface, BndesIndiretoDataInterface, PlotRiscoJuridicoDataInterface, ProcessosAlertaDataInterface, OcorrenciasEmentaDataInterface, EndividamentoDataInterface, InformacoesEconomicoFinanceirasDataInterface, BasicInfoGraphDataInterface, FormsDataInterface, ChequeSemFundoDataInterface } from './types'
import { GroupDiretoEIndireto, GroupIndireto } from './types/bndes'
import { UserInputDataInterface } from './types/user-input'
import { RatingDataInterface } from './types/rating'
import { UserTypeDataInterface } from './types/user-type'

export const readAggregatedFile = async ({ s3Instance, targetDocument, version }: { s3Instance: S3ClientType, targetDocument: string, version?: string }) => {
  const bucket = AWS_BUCKET_NAME
  const key = version || getAggregatedFileKey({ targetDocument })

  const data = await s3Instance.readFile({
    bucket,
    key,
    contentType: 'application/json',
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  return toCamelCase(data) as AggregatedFiles
}

export const readAggregatedFileLastModifiedDate = async ({ s3Instance, targetDocument, version }: { s3Instance: S3ClientType, targetDocument: string, version?: string }) => {
  const bucket = AWS_BUCKET_NAME
  const key = version || getAggregatedFileKey({ targetDocument })
  const head = await s3Instance.headFile({ bucket, key })
  return head.LastModified // Date
}

const getAggregatedFileKey = ({ targetDocument }: { targetDocument: string }) => {
  const internalScope = resolveScope()
  const key = `boanota/${internalScope}/applications/relatorio_cadastro/resource=aggregate/document=${targetDocument}/relatorio.json`

  return key
}

const getUserInputFileKey = ({ targetDocument }: { targetDocument: string }) => {
  const internalScope = resolveScope()
  const key = `boanota/${internalScope}/applications/relatorio_cadastro/resource=formulario_relatorio/document=${targetDocument}/json`

  return key
}

export const storeUserInputData = async ({ s3Instance, targetDocument, values }: { s3Instance: S3ClientType, targetDocument: string, values: UserInputDataInterface }) => {
  const bucket = AWS_BUCKET_NAME
  const key = getUserInputFileKey({ targetDocument })

  await s3Instance.writeFile({ bucket, key, data: values, contentType: 'application/json' })
}

interface BucketFile {
  key: string
  versionId?: string | null
}

export const readVisitasData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & BucketFile): Promise<VisitasDataInterface> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  if (data.outras_linhas) {
    delete data.outras_linhas
  }

  const formattedData = toCamelCase(data) as (Omit<VisitasDataInterface, 'dataHomologacao'> & { dataHomologacao: string })

  return {
    ...formattedData,
    dataDeferimento: factoryDate(formattedData.dataDeferimento),
    dataHomologacao: factoryDate(formattedData.dataHomologacao),
    dataRequerimento: factoryDate(formattedData.dataRequerimento)
  }
}

export const readSerasaData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & BucketFile): Promise<SerasaDataInterface> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as SerasaDataInterface

  return formattedData
}

export const readLogsulData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & BucketFile): Promise<LogsulDataInterface> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as LogsulDataInterface

  return {
    ...formattedData,
    data: {
      ...formattedData.data,
      faturamento: formattedData.data.faturamento.status === 'ok'
        ? {
            ...formattedData.data.faturamento,
            forecast: formattedData.data.faturamento.forecast.map(({ ds, ...item }) => ({ ...item, ds, date: factoryDate(ds) }))
          }
        : { ...formattedData.data.faturamento },
      cedente: formattedData.data.cedente.status === 'ok'
        ? {
            ...formattedData.data.cedente,
            dataConstituida: factoryDate(formattedData.data.cedente.dataConstituida)
          }
        : { ...formattedData.data.cedente },
      consultaSarasa: formattedData.data.consultaSarasa.status === 'ok'
        ? {
            ...formattedData.data.consultaSarasa,
            dataAtualizacao: factoryDate(formattedData.data.consultaSarasa.dataAtualizacao),
            dataConsulta: factoryDate(formattedData.data.consultaSarasa.dataConsulta),
            dataFundacao: factoryDate(formattedData.data.consultaSarasa.dataFundacao)
          }
        : { ...formattedData.data.consultaSarasa }
    }
  }
}

export const readPgfn = async ({ s3Instance, fgts, naoPrevidenciario, previdenciario }: {
  s3Instance: S3ClientType,
  fgts: BucketFile,
  naoPrevidenciario: BucketFile,
  previdenciario: BucketFile,
}): Promise<{
  fgts: PgfnDataInterface
  naoPrevidenciario: PgfnDataInterface
  previdenciario: PgfnDataInterface
}> => {
  const bucket = AWS_BUCKET_NAME

  const [fgtsData, naoPrevidenciarioData, previdenciarioData] = await Promise.all([fgts, naoPrevidenciario, previdenciario].map(async ({ key, versionId }) => {
    const internalVersionId = versionId || undefined
    const data = await s3Instance.readFile({
      bucket,
      key,
      versionId: internalVersionId,
      options: {
        responseExpires: addSeconds(new Date(), 1)
      }
    })
    const formattedData = toCamelCase(data) as PgfnDataInterface
    return formattedData
  }))

  return {
    fgts: fgtsData,
    naoPrevidenciario: naoPrevidenciarioData,
    previdenciario: previdenciarioData
  }
}

export const readBasicInfoData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & BucketFile): Promise<BasicInfoDataInterface> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as BasicInfoDataInterface

  const dataInicioAtividade = formattedData.dadosBasicos.datas.dataInicioAtividade?.toString().split('T')[0]
  const dataSituacaoCadastral = formattedData.dadosBasicos.datas.dataSituacaoCadastral?.toString().split('T')[0]
  const dataSituacaoEspecial = formattedData.dadosBasicos.datas.dataSituacaoEspecial?.toString().split('T')[0]
  const dataExclusaoSimples = formattedData.dadosBasicos.datas.dataExclusaoSimples?.toString().split('T')[0]
  const dataOpcaoSimples = formattedData.dadosBasicos.datas.dataOpcaoSimples?.toString().split('T')[0]
  const dataExclusaoMei = formattedData.dadosBasicos.datas.dataExclusaoMei?.toString().split('T')[0]
  const dataOpcaoMei = formattedData.dadosBasicos.datas.dataOpcaoMei?.toString().split('T')[0]

  return {
    ...formattedData,
    dadosBasicos: {
      ...formattedData.dadosBasicos,
      datas: {
        ...formattedData.dadosBasicos.datas,
        dataInicioAtividade: factoryDate(dataInicioAtividade),
        dataSituacaoCadastral: factoryDate(dataSituacaoCadastral),
        dataSituacaoEspecial: dataSituacaoEspecial ? factoryDate(dataSituacaoEspecial) : undefined,
        dataExclusaoSimples: dataExclusaoSimples ? factoryDate(dataExclusaoSimples) : undefined,
        dataOpcaoSimples: dataOpcaoSimples ? factoryDate(dataOpcaoSimples) : undefined,
        dataExclusaoMei: dataExclusaoMei ? factoryDate(dataExclusaoMei) : undefined,
        dataOpcaoMei: dataOpcaoMei ? factoryDate(dataOpcaoMei) : undefined
      }
    }
  }
}

export const readBasicInfoGraphData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & BucketFile): Promise<BasicInfoGraphDataInterface> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as BasicInfoGraphDataInterface

  return formattedData
}

export const readProtestoData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & BucketFile): Promise<ProtestoDataInterface> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as ProtestoDataInterface

  return formattedData
}

export const readPepData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & BucketFile): Promise<PepDataInterface> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as PepDataInterface

  return formattedData.map(item => ({
    ...item,
    data: item.data.map(subData => ({
      ...subData,
      dataInicioExercicio: factoryDate(subData.dataInicioExercicio),
      dataFimExercicio: factoryDate(subData.dataFimExercicio),
      dataFimCarencia: subData.dataFimCarencia ? factoryDate(subData.dataFimCarencia) : undefined
    }))
  }))
}

export const readBndesData = async ({ s3Instance, indireta, diretaEIndireta }: { s3Instance: S3ClientType, indireta: BucketFile, diretaEIndireta: BucketFile }): Promise<{ indireta: BndesIndiretoDataInterface, diretaEIndireta: BndesDiretoEIndiretoDataInterface }> => {
  const bucket = AWS_BUCKET_NAME

  const [indiretaData, diretaEIndiretaData] = await Promise.all([{ value: indireta, key: 'indireta' }, { value: diretaEIndireta, key: 'diretaEIndireta' }].map(async ({ value: { key, versionId }, key: rootKey }) => {
    const internalVersionId = versionId || undefined

    const data = await s3Instance.readFile({
      bucket,
      key,
      versionId: internalVersionId,
      options: {
        responseExpires: addSeconds(new Date(), 1)
      }
    })
    const formattedData = toCamelCase(data)

    const formatIndireto = (input: GroupIndireto) => {
      const records = input.records
        ? {
            ...input.records,
            dataDaContratacao: factoryDate(input.records.dataDaContratacao.toString().replace(/(\d){2}\/(\d){2}\/(\d){4}/g, '$3-$2-$1')),
            valorDaOperacaoEmReais: parseFloat(input.records.valorDaOperacaoEmReais.toString()),
            valorDesembolsadoReais: parseFloat(input.records.valorDesembolsadoReais.toString()),
            juros: parseFloat(input.records.juros.toString()),
            prazoCarenciaMeses: parseInt(input.records.prazoCarenciaMeses.toString()),
            prazoAmortizacaoMeses: parseInt(input.records.prazoAmortizacaoMeses.toString()),
            recordCount: parseInt(input.records.recordCount.toString())
          }
        : undefined
      return {
        ...input,
        records
      }
    }

    const formatDiretoEIndireto = (input: GroupDiretoEIndireto) => {
      const records = input.records
        ? {
            ...input.records,
            dataDaContratacao: factoryDate(input.records.dataDaContratacao.toString().replace(/(\d){4}\/(\d){2}\/(\d){2}/g, '$1-$2-$3')),
            valorContratadoReais: parseFloat(input.records.valorContratadoReais.toString()),
            valorDesembolsadoReais: parseFloat(input.records.valorDesembolsadoReais.toString()),
            juros: parseFloat(input.records.juros.toString()),
            prazoCarenciaMeses: parseInt(input.records.prazoCarenciaMeses.toString()),
            prazoAmortizacaoMeses: parseInt(input.records.prazoAmortizacaoMeses.toString()),
            recordCount: parseInt(input.records.recordCount.toString())
          }
        : undefined
      return {
        ...input,
        records
      }
    }

    if (rootKey === 'indireta') {
      const formattedDataCoercion = formattedData as BndesIndiretoDataInterface
      return {
        target: formattedDataCoercion.target.map(formatIndireto),
        group: formattedDataCoercion.group.map(formatIndireto)
      }
    } else {
      const formattedDataCoercion = formattedData as BndesDiretoEIndiretoDataInterface
      return {
        target: formattedDataCoercion.target.map(formatDiretoEIndireto),
        group: formattedDataCoercion.group.map(formatDiretoEIndireto)
      }
    }
  }))

  return {
    indireta: indiretaData as BndesIndiretoDataInterface,
    diretaEIndireta: diretaEIndiretaData as BndesDiretoEIndiretoDataInterface
  }
}

export const readRiscoJuridicoData = async ({ s3Instance, plotRiscoJuridico, processosAlerta, ocorrenciasEmenta }: {
  s3Instance: S3ClientType,
  plotRiscoJuridico: BucketFile,
  processosAlerta: BucketFile,
  ocorrenciasEmenta: BucketFile,
}): Promise<{
  plotRiscoJuridico: PlotRiscoJuridicoDataInterface,
  processosAlerta: ProcessosAlertaDataInterface,
  ocorrenciasEmenta: OcorrenciasEmentaDataInterface
}> => {
  const bucket = AWS_BUCKET_NAME

  const getPlotRiscoJuridicoData = async ({ key, versionId }: BucketFile) => {
    const internalVersionId = versionId || undefined
    const data = await s3Instance.readFile({
      bucket,
      key,
      versionId: internalVersionId,
      options: {
        responseExpires: addSeconds(new Date(), 1)
      }
    })
    const formattedData = toCamelCase(data) as PlotRiscoJuridicoDataInterface

    return formattedData
  }

  const getProcesosAlertaData = async ({ key, versionId }: BucketFile) => {
    const internalVersionId = versionId || undefined
    const data = await s3Instance.readFile({
      bucket,
      key,
      versionId: internalVersionId,
      options: {
        responseExpires: addSeconds(new Date(), 1)
      }
    })
    const formattedData = toCamelCase(data) as ProcessosAlertaDataInterface

    return formattedData
  }

  const getOcorrenciasEmentaData = async ({ key, versionId }: BucketFile) => {
    const internalVersionId = versionId || undefined
    const data = await s3Instance.readFile({
      bucket,
      key,
      versionId: internalVersionId,
      options: {
        responseExpires: addSeconds(new Date(), 1)
      }
    })
    const formattedData = toCamelCase(data) as OcorrenciasEmentaDataInterface

    return formattedData
  }

  const [plotRiscoJuridicoData, processosAlertaData, ocorrenciasEmentaData] = await Promise.all([
    getPlotRiscoJuridicoData(plotRiscoJuridico),
    getProcesosAlertaData(processosAlerta),
    getOcorrenciasEmentaData(ocorrenciasEmenta)
  ])

  return {
    plotRiscoJuridico: plotRiscoJuridicoData,
    processosAlerta: processosAlertaData,
    ocorrenciasEmenta: ocorrenciasEmentaData
  }
}

export const readEndividamentoData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & BucketFile): Promise<EndividamentoDataInterface> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as EndividamentoDataInterface

  return {
    ...formattedData,
    fundos: formattedData.fundos.map(fundo => ({
      ...fundo,
      instituicao: fundo['instituição'] as string,
      observacoes: fundo['observações'] as string
    })),
    bancos: formattedData.bancos.map(banco => ({
      ...banco,
      instituicao: banco['instituição'] as string,
      observacoes: banco['observações'] as string
    }))
  }
}

export const readInformacoesEconomicoFinanceirasData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & BucketFile): Promise<InformacoesEconomicoFinanceirasDataInterface> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as InformacoesEconomicoFinanceirasDataInterface

  return {
    ...formattedData,
    dataIndicadores: formattedData.dataIndicadores.map(indicador => ({
      ...indicador,
      data: factoryDate(indicador.data)
    })),
    dataCaixa: formattedData.dataCaixa.map(caixa => ({
      ...caixa,
      data: factoryDate(caixa.data)
    }))
  }
}

export const readUserInputData = async ({ s3Instance, targetDocument, key, version }: { s3Instance: S3ClientType } & { targetDocument: string, version?: string } & Partial<BucketFile>): Promise<UserInputDataInterface | undefined> => {
  if (version) {
    const bucket = AWS_BUCKET_NAME
    const internalKey = key || getUserInputFileKey({ targetDocument })

    const data = await s3Instance.readFile({
      bucket,
      key: internalKey,
      versionId: undefined,
      options: {
        responseExpires: addSeconds(new Date(), 1)
      }
    })
    const newParser = () => {
      return parseAllComments(toCamelCase(data) as UserCommentsResponseInterface)
    }
    const oldParser = () => {
      interface DataResponse {
        user: string
        timestamp: string
        value: string
      }

      const responseArrayData = toCamelCase(data) as Array<DataResponse>
      const formattedArrayData = responseArrayData.map(item => JSON.parse(item.value)) as Array<Omit<UserInputDataInterface, 'metadata'>>
      const formattedData = formattedArrayData.length > 0 ? formattedArrayData[formattedArrayData.length - 1] : undefined

      if (!formattedData) {
        return undefined
      }

      return formattedData
    }
    const formattedData = key?.includes('blob_comentarios_aggregate') ? newParser() : oldParser()

    return {
      ...formattedData,
      metadata: {
        locked: false
      }
    }
  } else {
    const { blobs } = await API.comments.all({ document: targetDocument })

    const parsedBlobs = parseAllComments(toCamelCase(blobs) as UserCommentsResponseInterface)

    const formattedData = toCamelCase(parsedBlobs) as Omit<UserInputDataInterface, 'metadata'>
    return {
      ...formattedData,
      metadata: {
        locked: false
        // ...lockMetadata
      }
    }
  }
}

export const readFormsData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & Partial<BucketFile>): Promise<FormsDataInterface | undefined> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  if (!key) {
    return undefined
  }

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as FormsDataInterface

  return formattedData
}

export const readChequeSemFundoData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & BucketFile): Promise<ChequeSemFundoDataInterface> => {
  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as ChequeSemFundoDataInterface

  return formattedData
}

export const readRatingData = async ({ s3Instance, key, versionId }: { s3Instance: S3ClientType } & Partial<BucketFile>): Promise<RatingDataInterface | undefined> => {
  if (!key) {
    return undefined
  }

  const bucket = AWS_BUCKET_NAME
  const internalVersionId = versionId || undefined

  const data = await s3Instance.readFile({
    bucket,
    key,
    versionId: internalVersionId,
    contentType: 'application/json',
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as RatingDataInterface

  return formattedData
}

export const readUserType = async ({ s3Instance, key }: { s3Instance: S3ClientType } & BucketFile): Promise<UserTypeDataInterface> => {
  const bucket = AWS_BUCKET_NAME

  const data = await s3Instance.readFile({
    bucket,
    key,
    contentType: 'application/json',
    options: {
      responseExpires: addSeconds(new Date(), 1)
    }
  })
  const formattedData = toCamelCase(data) as UserTypeDataInterface

  return formattedData
}
