import axios from 'axios'

import { toCamelCase } from '../../utils/formatter'
import { formatWithTimezone, factoryDate } from '../../utils/date'
import { ResponseWithErrorMessage } from '../../utils/hooks'

import { FORECAST_BASE_URL } from '../../config/env'

export const MAX_DAYS = 365 * 3

interface Item {
  date: Date
  value: number
}

export interface ApiProps {
  data: Item[]
  period: number
}

export interface ForeCast {
  date: Date
  trend: number
  yhatLower: number
  yhatUpper: number
  trendLower: number
  trendUpper: number
  additiveTerms: number
  additiveTermsLower: number
  additiveTermsUpper: number
  weekly?: number
  weeklyLower?: number
  weeklyUpper?: number
  yearly?: number
  yearlyLower?: number
  yearlyUpper?: number
  multiplicativeTerms: number
  multiplicativeTermsLower: number
  multiplicativeTermsUpper: number
  yhat: number
}

const _computeForecast = async (props: ApiProps) => {
  const url = `${FORECAST_BASE_URL}/v1/forecast`

  const propsParam = props.data
    .sort((a, b) => a.date.getTime() - b.date.getTime())
    .map(item => ({
      ds: formatWithTimezone(item.date, 'yyyy-MM-dd'),
      y: item.value
    }))

  const params = {
    train: propsParam,
    periods: props.period
  }

  interface ForecastResponse {
    ds: number
    trend: number
    yhatLower: number
    yhatUpper: number
    trendLower: number
    trendUpper: number
    additiveTerms: number
    additiveTermsLower: number
    additiveTermsUpper: number
    weekly: number
    weeklyLower: number
    weeklyUpper: number
    yearly: number
    yearlyLower: number
    yearlyUpper: number
    multiplicativeTerms: number
    multiplicativeTermsLower: number
    multiplicativeTermsUpper: number
    yhat: number
  }

  const { data } = await axios.post(url, params, {
    timeout: 30 * 1000 // 30 seconds
  }).catch((err: ResponseWithErrorMessage) => {
    const message = err.response?.data?.message || err.message

    if (message === 'Erro ao treinar o modelo: Dataframe has less than 2 non-NaN rows.') {
      throw new Error('Unable to calculate forecast. The training dataset is too small')
    }

    if (message === 'Endpoint request timed out') {
      throw new Error('Timed out, try another data range')
    }

    throw new Error(`Please forward the error message to support:\n${message}`)
  })

  const formattedData = toCamelCase(data) as ForecastResponse[]

  return formattedData.map(({ ds, ...item }) => ({
    ...item,
    date: factoryDate(ds)
  }))
}
export const computeForecast = async (props: ApiProps) => {
  if (props.data.length === 0) {
    return []
  }

  const data = props.data.sort((a, b) => a.date.getTime() - b.date.getTime())
  const length = data.length
  const min = Math.max(length - MAX_DAYS, 0)
  const max = length
  const internalData = data.slice(min, max)

  return _computeForecast({ ...props, data: internalData })
    .catch((err: Error) => {
      if (err.message.startsWith('timeout of')) {
        throw new Error('Oops, we were unable to calculate the forecast for this range. Try to select a smaller range')
      }
      throw err
    })
}
