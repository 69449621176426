import pack from '../../package.json'

import logoSulbrasil from '../assets/img/sulbrasil/logo.png'
import logoIvtGrade from '../assets/img/logo-ivtgrade.svg'
import logoBoaNota from '../assets/img/boanota/full_black.svg'

export const VERSION = pack.version
export const ENV: string = process.env.REACT_APP_ENV ?? 'development'
export const BUILD_ENV: string | undefined = process.env.REACT_APP_BUILD_ENV
export const SCOPE = process.env.REACT_APP_SCOPE || ''

console.log(`v${VERSION} - ${BUILD_ENV}`)

//  AWS COGNITO ENV
export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION || ''
export const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID || ''
export const AWS_USER_POOLS_WEB_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || ''
export const AWS_COGNITO_AUTH_DOMAIN = process.env.REACT_APP_AWS_COGNITO_AUTH_DOMAIN || ''
export const AWS_COGNITO_SHARED_DOMAIN = process.env.REACT_APP_SHARED_DOMAIN || ''

// AWS S3 ENV
export const AWS_BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME || ''
export const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID || ''
export const AWS_IDENTITY_POOL_REGION = process.env.REACT_APP_AWS_IDENTITY_POOL_REGION || ''

const REQUIRED_ENVS = {
  SCOPE,
  BUILD_ENV,
  AWS_COGNITO_REGION,
  AWS_USER_POOLS_ID,
  AWS_USER_POOLS_WEB_CLIENT_ID,
  AWS_BUCKET_NAME,
  AWS_IDENTITY_POOL_ID,
  AWS_IDENTITY_POOL_REGION,
  AWS_COGNITO_SHARED_DOMAIN
}

Object.entries(REQUIRED_ENVS).forEach(([env, value]) => {
  if (!value) {
    throw new Error(`Missing environment variable: ${env}`)
  }
})

const commonEnvs: { [key: string]: string } = {
  FORECAST_BASE_URL: 'https://bpe41lp324.execute-api.us-east-1.amazonaws.com/staging'
}

const configs: { [envKey: string]: { [key: string]: string } } = {
  prod: {
    ARCHIVE_BASE_URL: 'https://tkchfv7ytc.execute-api.us-east-1.amazonaws.com/Prod',
    RATING_BASE_URL: 'https://xm3qf78q9d.execute-api.us-east-1.amazonaws.com/Prod',
    COMMENTS_BASE_URL: 'https://pky1gpfc7e.execute-api.us-east-1.amazonaws.com/Prod',
    SPREAD_API_BASE_URL: 'https://z5wruhgk4j.execute-api.us-east-1.amazonaws.com/Prod2'
  },
  staging: {
    ARCHIVE_BASE_URL: 'https://rlzj2aox96.execute-api.us-east-1.amazonaws.com/Staging',
    RATING_BASE_URL: 'https://2odilfao8g.execute-api.us-east-1.amazonaws.com/Staging',
    COMMENTS_BASE_URL: 'https://8c77wcrcpj.execute-api.us-east-1.amazonaws.com/Staging',
    SPREAD_API_BASE_URL: 'https://mfnyv9zq06.execute-api.us-east-1.amazonaws.com/Staging'
  },
  dev: {
    ARCHIVE_BASE_URL: 'https://x0xlnu982k.execute-api.us-east-1.amazonaws.com/Dev',
    RATING_BASE_URL: 'https://5pgemy0dgh.execute-api.us-east-1.amazonaws.com/Dev',
    COMMENTS_BASE_URL:
      'https://3wswlq1vqf.execute-api.us-east-1.amazonaws.com/Dev',
    SPREAD_API_BASE_URL: 'https://rruzh3pbjl.execute-api.us-east-1.amazonaws.com/Dev'
  }
}

if (!BUILD_ENV) {
  throw new Error('Missing environment variable: BUILD_ENV')
}

const currentEnvs = { ...commonEnvs, ...configs[BUILD_ENV] }

export const { ARCHIVE_BASE_URL, FORECAST_BASE_URL, COMMENTS_BASE_URL, SPREAD_API_BASE_URL, RATING_BASE_URL } = currentEnvs

const resolvedStage = (() => {
  const stage = BUILD_ENV.toLowerCase()
  switch (stage) {
    case 'prod':
      return ''
    default:
      return `${stage}-`
  }
})()

const resolvedScope = (() => {
  const scope = SCOPE.toLowerCase()
  switch (scope) {
    case 'atrio':
      return 'atrioasset'
    case 'ivtgrade':
      return logoIvtGrade
    case 'sulbrasil':
      return 'sbcredito'
    default:
      return scope
  }
})()

export const SPREAD_BASE_URL = `https://spread.${resolvedStage}${resolvedScope}.boanota.credit`

export const HEAD_TITLE = process.env.REACT_APP_HEAD_TITLE || ''
export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR || ''
export const SECONDARY_COLOR = process.env.REACT_APP_SECONDARY_COLOR || ''
export const LIST_BACKGROUND_COLOR = process.env.REACT_APP_LIST_BACKGROUND_COLOR || ''
export const LIST_COLOR = process.env.REACT_APP_LIST_COLOR || ''

const resolveLogo = (scope: string) => {
  switch (scope.toLowerCase()) {
    case 'sulbrasil':
      return logoSulbrasil
    default:
      return logoBoaNota
  }
}
export const LOGO = resolveLogo(SCOPE)
