import { UserInputDataInterface } from '../../lib/s3Themes/types/user-input'
import { RatingDataInterface } from '../../lib/s3Themes/types/rating'
import { AggregatedFiles, BasicInfoDataInterface, BasicInfoGraphDataInterface, BndesDiretoEIndiretoDataInterface, BndesIndiretoDataInterface, ChequeSemFundoDataInterface, EndividamentoDataInterface, FormsDataInterface, InformacoesEconomicoFinanceirasDataInterface, LogsulDataInterface, OcorrenciasEmentaDataInterface, PepDataInterface, PgfnDataInterface, PlotRiscoJuridicoDataInterface, ProcessosAlertaDataInterface, ProtestoDataInterface, SerasaDataInterface, VisitasDataInterface } from '../../lib/s3Themes/types'

import { ListVersionsResult } from '../../services/api/archive'

import { PROGRESS_STATUS, PROGRESS_TYPES } from '../../utils/progress-status'

// Action Types
export const Types = {
  SET_DATA: 'MAIN_PAGE/SET_DATA',
  SET_ERROR: 'MAIN_PAGE/SET_ERROR'
}

type Keys = keyof typeof Types
type Values = typeof Types[Keys]

// Reducer
interface IInitialState {
  versions?: ListVersionsResult
  executionDate?: string
  aggregatedFiles?: AggregatedFiles
  aggregatedFileLMD?: Date
  visitasData?: VisitasDataInterface
  serasaData?: SerasaDataInterface
  pgfnData?: {
    fgts: PgfnDataInterface
    naoPrevidenciario: PgfnDataInterface
    previdenciario: PgfnDataInterface
  },
  basicInfoData?: BasicInfoDataInterface
  basicInfoGraphData?: BasicInfoGraphDataInterface
  logsulData?: LogsulDataInterface
  protestoData?: ProtestoDataInterface
  pepData?: PepDataInterface
  bndesData?: {
    indireta: BndesIndiretoDataInterface,
    diretaEIndireta: BndesDiretoEIndiretoDataInterface
  },
  riscoJuridicoData?: {
    plotRiscoJuridico: PlotRiscoJuridicoDataInterface
    processosAlerta: ProcessosAlertaDataInterface
    ocorrenciasEmenta: OcorrenciasEmentaDataInterface
  }
  endividamentoData?: EndividamentoDataInterface
  informacoesEconomicoFinanceirasData?: InformacoesEconomicoFinanceirasDataInterface
  userInputData?: UserInputDataInterface
  formsData?: FormsDataInterface
  chequeSemFundoData?: ChequeSemFundoDataInterface
  ratingData?: RatingDataInterface
  status: PROGRESS_STATUS
}
const initialState: IInitialState = {
  status: PROGRESS_TYPES.PENDING
}

interface IAction {
  type: Values,
  payload: Partial<IInitialState>
}

export default function reducer (state = initialState, action: IAction) {
  switch (action.type) {
    case Types.SET_DATA: {
      return {
        ...state,
        ...action.payload,
        status: PROGRESS_TYPES.LOADED
      }
    }
    case Types.SET_ERROR: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}

// Action Creators
export function setData (data: Omit<IInitialState, 'status'>): IAction {
  return {
    type: Types.SET_DATA,
    payload: data
  }
}

export function setError (): IAction {
  return {
    type: Types.SET_ERROR,
    payload: { status: PROGRESS_TYPES.ERROR }
  }
}
