import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Buffer } from 'buffer'

import { setData, setError } from './redux/stores/main'
import { setPermission, setUserType } from './redux/stores/metadata'

import lazyLoad from './lib/lazyLoad'
import { readAggregatedFile, readAggregatedFileLastModifiedDate, readBasicInfoData, readBasicInfoGraphData, readBndesData, readChequeSemFundoData, readEndividamentoData, readFormsData, readInformacoesEconomicoFinanceirasData, readLogsulData, readPepData, readUserType, readPgfn, readProtestoData, readRatingData, readRiscoJuridicoData, readSerasaData, readUserInputData, readVisitasData } from './lib/s3Themes'
import { StaticS3Client } from './lib/s3'
import { swal } from './lib/swal'

import { getIdToken, getPermissions } from './services/aws'
import API from './services/api'

import { useAppDispatch, useAppSelector, useHandleError } from './utils/hooks'
import { PROGRESS_TYPES } from './utils/progress-status'

import { ErrorBoundary, ErrorLoadPage, LazyLoadPage, LoadingContainer } from './components'

import NotFound from './pages/not-found'

// const TARGET_DOCUMENT = '04956796000101' // hispex
// const TARGET_DOCUMENT = '80276314000150' // fortesolo
// const TARGET_DOCUMENT = '20218787000170' // indrema
// const TARGET_DOCUMENT = '15681084000143' // caima
// const TARGET_DOCUMENT = '00001055000116' // rafitos
// const TARGET_DOCUMENT = '07737623000190' // Bodytech
// const TARGET_DOCUMENT = '17142572000108' // Auto-posto

const Main = lazyLoad(() => import('./pages/main'), ErrorLoadPage, 3, 1000)

function App ({ currentEmail, document, version: versionParams }: { currentEmail: string, document?: string | null, version?: string | null }): JSX.Element {
  const { handleError } = useHandleError()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state)

  const archivedVersionParam = versionParams
  const version = archivedVersionParam ? Buffer.from(archivedVersionParam, 'base64').toString('utf-8') : undefined

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const archivedVersionParam = versionParams
    const archivedVersion = archivedVersionParam ? Buffer.from(archivedVersionParam, 'base64').toString('utf-8') : undefined

    if (state.main.status !== PROGRESS_TYPES.PENDING || loading || !document) {
      return
    }

    setLoading(true)

    const fetchAggregatedFile = async () => {
      try {
        const accessToken = await getIdToken()
        const s3Instance = await StaticS3Client.getInstance({ accessToken })
        const aggregatedFiles = await readAggregatedFile({ s3Instance, targetDocument: document, version: archivedVersion })
          .catch(err => {
            console.error(err)
            swal.fire({
              title: 'Relatório não encontrado',
              html: <>
                <Typography variant='body1' component='p'>
                  Por favor, verifique se o número do CNPJ está correto e tente novamente.
                </Typography>
                <Typography variant='body1' component='p'>
                  Se o problema persistir, entre em contato com o suporte.
                </Typography>
              </>,
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#3f51b5',
              allowOutsideClick: false
            })
            dispatch(setError())
            return undefined
          })
        if (!aggregatedFiles) {
          return
        }

        const regexExecutionDate = /date=(.+)\//gm
        const executionDate = regexExecutionDate.exec(aggregatedFiles.basicInfo?.key)?.[1]

        const aggregatedFileLMD = await readAggregatedFileLastModifiedDate({ s3Instance, targetDocument: document, version: archivedVersion })
        const [permissionsData, versionsData, visitasData, serasaData, pgfnData, basicInfoData, basicInfoGraphData, logsulData, protestoData, pepData, bndesData, riscoJuridicoData, endividamentoData, informacoesEconomicoFinanceiraData, formsData, userInputData, chequeSemFundoData, ratingData, userTypeData] = await Promise.allSettled([
          getPermissions(),
          API.archive.list({ document }),
          readVisitasData({ s3Instance, ...aggregatedFiles.visitas }),
          readSerasaData({ s3Instance, ...aggregatedFiles.serasa }),
          readPgfn({
            s3Instance,
            fgts: aggregatedFiles.pgfnFgts,
            naoPrevidenciario: aggregatedFiles.pgfnNaoPrevidenciario,
            previdenciario: aggregatedFiles.pgfnPrevidenciario
          }),
          readBasicInfoData({ s3Instance, ...aggregatedFiles.basicInfo }),
          readBasicInfoGraphData({ s3Instance, ...aggregatedFiles.basicInfoGraph }),
          readLogsulData({ s3Instance, ...aggregatedFiles.logsul }),
          readProtestoData({ s3Instance, ...aggregatedFiles.protesto }),
          readPepData({ s3Instance, ...aggregatedFiles.pep }),
          readBndesData({ s3Instance, diretaEIndireta: aggregatedFiles.bndesOperacoesContratadasNaFormaDiretaEIndiretaNaoAutomatica, indireta: aggregatedFiles.bndesOperacoesContratadasNaFormaIndiretaAutomatica }),
          readRiscoJuridicoData({ s3Instance, plotRiscoJuridico: aggregatedFiles.plotRiscoJuridico, processosAlerta: aggregatedFiles.processosAlerta, ocorrenciasEmenta: aggregatedFiles.ocorrenciasEmenta }),
          readEndividamentoData({ s3Instance, ...aggregatedFiles.endividamento }),
          readInformacoesEconomicoFinanceirasData({ s3Instance, ...aggregatedFiles.informacoesEconomicoFinanceiras }),
          readFormsData({ s3Instance, ...(aggregatedFiles.forms || {}) }),
          readUserInputData({ s3Instance, targetDocument: document, key: aggregatedFiles.userInput?.key, version: archivedVersion }),
          readChequeSemFundoData({ s3Instance, ...aggregatedFiles.chequeSemFundo }),
          readRatingData({
            s3Instance,
            key: aggregatedFiles.rating?.key
          }),
          readUserType({
            s3Instance,
            key: 'boanota/user_type.json'
          })
        ])

        if (permissionsData.status === 'fulfilled') {
          dispatch(setPermission(permissionsData.value))
        }

        if (userTypeData.status === 'fulfilled') {
          dispatch(setUserType(userTypeData.value))
        }

        dispatch(setData({
          executionDate,
          aggregatedFiles,
          aggregatedFileLMD,
          versions: versionsData.status === 'fulfilled' ? versionsData.value : undefined,
          visitasData: visitasData.status === 'fulfilled' ? visitasData.value : undefined,
          serasaData: serasaData.status === 'fulfilled' ? serasaData.value : undefined,
          pgfnData: pgfnData.status === 'fulfilled' ? pgfnData.value : undefined,
          basicInfoData: basicInfoData.status === 'fulfilled' ? basicInfoData.value : undefined,
          basicInfoGraphData: basicInfoGraphData.status === 'fulfilled' ? basicInfoGraphData.value : undefined,
          logsulData: logsulData.status === 'fulfilled' ? logsulData.value : undefined,
          protestoData: protestoData.status === 'fulfilled' ? protestoData.value : undefined,
          pepData: pepData.status === 'fulfilled' ? pepData.value : undefined,
          bndesData: bndesData.status === 'fulfilled' ? bndesData.value : undefined,
          riscoJuridicoData: riscoJuridicoData.status === 'fulfilled' ? riscoJuridicoData.value : undefined,
          endividamentoData: endividamentoData.status === 'fulfilled' ? endividamentoData.value : undefined,
          informacoesEconomicoFinanceirasData: informacoesEconomicoFinanceiraData.status === 'fulfilled' ? informacoesEconomicoFinanceiraData.value : undefined,
          formsData: formsData.status === 'fulfilled' ? formsData.value : undefined,
          userInputData: userInputData.status === 'fulfilled' ? userInputData.value : undefined,
          chequeSemFundoData: chequeSemFundoData.status === 'fulfilled' ? chequeSemFundoData.value : undefined,
          ratingData: ratingData.status === 'fulfilled'
            ? ratingData.value
            : undefined
        }))
      } catch (err) {
        handleError(err as Error)
        dispatch(setError())
      } finally {
        setLoading(false)
      }
    }

    fetchAggregatedFile()
  }, [state, loading, document, versionParams])

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          flexDirection: 'column',
          display: 'flex',
          overflow: 'hidden'
        }}
      >
        {!document
          ? <NotFound />
          : loading
            ? <LoadingContainer />
            : <LazyLoadPage>
              <Routes>
                <Route path='/' element={<ErrorBoundary><Main targetDocument={document || ''} currentVersion={version} currentEmail={currentEmail} /></ErrorBoundary>} />
                <Route path='*' element={<Navigate to='/' state={{ from: location }} replace />} />
              </Routes>
            </LazyLoadPage>}
      </Box>
    </Box>
  )
}

export default App
