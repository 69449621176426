import React from 'react'
import Graph, { Options } from 'react-graph-vis'
import { useResizeDetector } from 'react-resize-detector'
import { Box, Typography } from '@mui/material'

import { visNetworkOptions } from '../../lib/graph-js'
import { hashObject } from '../../utils/crypto'

import './style.module.scss'

export interface GrafoVisProps {
  error?: boolean
  loaded?: boolean
  data: {
    nodes: Array<{
      id: string
      label: string
      group: string
      metadata: {
        cpfCnpj: string
        missingDocument: any
      }
    }>,
    edges: Array<{
      id: string
      from: string
      to: string
      label: string
      metadata: Record<string, unknown>
    }>,
    options?: Partial<Options>
  }
}
const GrafoVis = ({ data, error, loaded }: GrafoVisProps) => {
  const { options, ...graphData } = data || {}
  const { height, ref } = useResizeDetector()

  const rede = {
    nodes:
      graphData?.nodes?.map(item => ({
        ...item,
        group: item.group // toCamelCase(item.group)
      })) || [],
    edges: graphData?.edges || []
  }
  const graphGrafo = rede

  if (!loaded && !error) {
    return <>Carregando</>
  }

  return error
    ? (
      <>
        <Typography>Falha ao carregar grafo</Typography>
      </>
      )
    : (
      <Box minHeight='60vh' maxHeight='70vh' ref={ref} my={3}>
        <Graph
          key={hashObject(graphGrafo)}
          style={{ height: '100%' }}
          graph={graphGrafo}
          options={{
            ...options,
            height:
              height?.toString() ||
              options?.height?.toString() ||
              visNetworkOptions?.height?.toString()
          }}
        />
      </Box>
      )
}

export default GrafoVis
