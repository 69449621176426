import { toCamelCase } from '../../utils/formatter'

import { UserInputDataInterface } from '../s3Themes/types/user-input'

const jsonFieldKeys = ['forecast', 'forecastV2', 'recommendations', 'fees']

type CommentField = Array<{
  timestamp: string,
  user: string,
  value: string
}>

export interface UserCommentsResponseInterface {
  basicInfo: {
    manager?: CommentField
    regional?: CommentField
    analyst?: CommentField
    numberOfEmployees?: CommentField
    sheetValue?: CommentField
    averageTicket?: CommentField
    committee?: CommentField
  }
  groupDistribution: {
    comment?: CommentField
    bndesComment?: CommentField
  }
  debit: {
    comment?: CommentField
  }
  financial: {
    economicAndFinancialInformationComment?: CommentField
    forecast?: CommentField
    forecastV2?: CommentField
    forecastComment?: CommentField
  }
  rating: {
    commercialLawsuit: {
      commercialManager: CommentField
      regionalManager: CommentField
      lineDetails?: CommentField
    },
    suggestions: {
      credit?: CommentField
      comercialDirector?: CommentField
      operationalDirector?: CommentField
      advice?: CommentField
      committee?: CommentField
      opinionOfTheAreas?: CommentField
      concentrations?: CommentField
      limitSuggestions?: CommentField
      analystReport?: CommentField
      opinionOfTheInvestmentCommittee?: CommentField
    }
    recommendations: CommentField
    fees: CommentField
  }
}

export const parseAllComments = (blobs: UserCommentsResponseInterface): UserInputDataInterface => {
  const parsedBlobs = parseBlob(blobs)
  const formattedData = toCamelCase(parsedBlobs) as Omit<UserInputDataInterface, 'metadata'>

  return {
    ...formattedData,
    financial: {
      ...(formattedData.financial || {})
    }
  }
}

const parseBlob = (blob: { [key: string]: any }): { [key: string]: any } => {
  return Object.fromEntries(Object.entries(blob).map(([key, item]) => {
    if (jsonFieldKeys.includes(key)) {
      return [key, JSON.parse(item?.[0].value)]
    } else if (item instanceof Array && item[0] instanceof Object && 'value' in item[0] && 'timestamp' in item[0] && 'user' in item[0] && 'timestamp' in item[0]) {
      return [key, item?.[0].value]
    } else {
      return [key, parseBlob(item)]
    }
  }))
}
