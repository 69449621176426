import axios from 'axios'

import { getIdToken } from '../aws'

import { ResponseWithErrorMessage } from '../../utils/hooks'

import { SPREAD_API_BASE_URL } from '../../config/env'

export interface UpdateSpreadProps {
  document: string
  spreadType: 'normal' | 'consolidado'
}

export const updateSpread = async ({ document, spreadType }: UpdateSpreadProps): Promise<void> => {
  const url = `${SPREAD_API_BASE_URL}/update-spread-relatorio`

  const token = await getIdToken()

  const payload = {
    spread_type: spreadType,
    document
  }

  await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).catch((err: ResponseWithErrorMessage) => {
    if (err.response?.data?.error?.includes('NoSuchKey')) {
      throw new Error('Não foi possível encontrar o documento')
    }

    const message = err.response?.data?.details ?? err.response?.data?.message ?? err.message

    throw new Error(`Envia essa mensagem de erro para o suporte:\n${message}`)
  })
}

export const existsSpread = async ({ cnpj }: { cnpj: string }) => {
  const cleanCnpj = cnpj.replace(/\D/g, '')
  const idToken = await getIdToken()

  const { data } = await axios.get<{ tem_spread: boolean, tem_spread_consolidado: boolean }>(`${SPREAD_API_BASE_URL}/tem-spread`, {
    params: { id: cleanCnpj },
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })

  const output = {
    hasSpread: data.tem_spread,
    hasSpreadConsolidado: data.tem_spread_consolidado
  }

  return output
}
