import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import axios from 'axios'
import { useSWRConfig } from 'swr'
import { useSnackbar } from 'notistack'

import API from '../../services/api'
import { getEmail } from '../../services/aws'

import { FETCH_KEY, TIMEOUT_EDIT_MODE } from '../../config/constant'

import { ResponseWithErrorMessage, useAppSelector } from '../../utils/hooks'
import { toCamelCase } from '../../utils/formatter'

import { swal } from '../swal'
import { parseAllComments, UserCommentsResponseInterface } from './parser'

export const useCommentsField = (fieldName: string) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { mutate } = useSWRConfig()
  const [state, setState] = useState<{ editable: boolean, email?: string }>({ editable: false })
  const fieldNameRef = useRef<string | null | undefined>(null)

  const cnpj = useAppSelector(state => state.main.basicInfoData?.dadosBasicos.cnpj)
  const { getValues } = useFormContext()

  useEffect(() => {
    const run = async () => {
      try {
        const email = await getEmail()
        setState(prev => ({ ...prev, email }))
      } catch (error) {
        console.error(error)
      }
    }

    run()
  }, [])

  useEffect(() => {
    fieldNameRef.current = fieldName
  }, [fieldName])

  const lockField = useCallback(async () => {
    if (!fieldNameRef.current || !cnpj) {
      return
    }

    const snackbarId = enqueueSnackbar('Liberando campo para edição...', { variant: 'info', persist: true })
    try {
      const allComments = await API.comments.all({ document: cnpj })
      mutate(FETCH_KEY, parseAllComments(allComments.blobs as UserCommentsResponseInterface), {
        revalidate: false,
        populateCache: true
      })
      await API.comments.lock({ document: cnpj, field: fieldNameRef.current })
      setState({ editable: true })
    } catch (error) {
      const errorMessage = axios.isAxiosError(error) ? (error as ResponseWithErrorMessage).response.data?.message : (error as Error).message

      if (errorMessage?.includes('User type is not allowed to edit this comment field')) {
        swal.fire({
          title: 'Erro',
          text: 'Você não tem permissão para editar este campo',
          icon: 'error'
        })
        return
      }

      if (axios.isAxiosError(error)) {
        const responseData = toCamelCase((error as ResponseWithErrorMessage).response.data) as { message: string, lockMetadata: { user: string, timestamp: string } }

        if (!responseData.message.includes('File is already locked')) {
          swal.fire({
            title: 'Erro',
            text: errorMessage,
            icon: 'error'
          })
          return
        }

        const lastLock = new Date(parseFloat(responseData.lockMetadata.timestamp) * 1000)
        const moreThanMaxLockTime = new Date().getTime() - lastLock.getTime() >= TIMEOUT_EDIT_MODE
        const amITheUserThatLocked = responseData.lockMetadata.user === state.email

        if (moreThanMaxLockTime) {
          await API.comments.unlock({ document: cnpj, field: fieldNameRef.current })
          await API.comments.lock({ document: cnpj, field: fieldNameRef.current })
          setState({ editable: true })
          return
        } else if (amITheUserThatLocked) {
          setState({ editable: true })
        } else {
          swal.fire({
            title: 'Erro',
            text: 'Este campo está sendo editado por outro usuário',
            icon: 'error'
          })
          return
        }
      }
      console.error(errorMessage)
    } finally {
      closeSnackbar(snackbarId)
    }
  }, [fieldNameRef, cnpj, state.email, closeSnackbar, enqueueSnackbar, mutate])

  const saveField = useCallback(async () => {
    if (!fieldNameRef.current || !cnpj) {
      return
    }

    const snackbarId = enqueueSnackbar('Salvando campo...', { variant: 'info', persist: true })
    const value = getValues(fieldNameRef.current)
    try {
      const text = typeof value === 'string' ? value : JSON.stringify(value)
      await API.comments.put({ document: cnpj, field: fieldNameRef.current, text })
      setState({ editable: false })
    } catch (error) {
      const errorMessage = axios.isAxiosError(error) ? ((error as ResponseWithErrorMessage).response.data.message || '') : (error as Error).message

      if (errorMessage.includes('User type is not allowed to edit this comment field')) {
        swal.fire({
          title: 'Erro',
          text: 'Você não tem permissão para editar este campo',
          icon: 'error'
        })
        return
      }

      if (errorMessage.includes('Only the user who requested the lock can put')) {
        swal.fire({
          title: 'Erro',
          text: 'Você não é o usuário que solicitou o bloqueio do campo para edição',
          icon: 'error'
        })
      } else if (errorMessage.includes('Field is not enabled for editing')) {
        swal.fire({
          title: 'Erro',
          text: 'O campo não está habilitado para edição',
          icon: 'error'
        })
        setState({ editable: false })
      } else {
        swal.fire({
          title: 'Erro',
          text: errorMessage,
          icon: 'error'
        })
      }

      console.error(error)
    } finally {
      closeSnackbar(snackbarId)
    }
  }, [fieldNameRef, cnpj, closeSnackbar, enqueueSnackbar, getValues])

  return {
    ...state,
    lockField,
    saveField
  }
}
